import { PrimaryButton, Text } from '@moonpig/launchpad-components';
import { FC, useState, useCallback, useEffect } from 'react';
import { Spacer } from './Spacer';


export const DadJoke: FC = () => {
  const [dadJoke, setDadJoke] = useState<string>('');

  const fetchDadJoke = useCallback(async () => {
    try {
      const { joke } = await fetch('https://icanhazdadjoke.com/', {
        headers: {
          Accept: 'application/json',
        },
      }).then((r) => {
        return r.json();
      });
      setDadJoke(joke);
    } catch (err) {
      console.warn('Failed to fetch Dad joke: ', err);
    }
  }, []);

  useEffect(() => {
    fetchDadJoke()
  }, [fetchDadJoke])

  const [answerRevealed, setAnswerRevealed] = useState(false);
  const handleRequestAnother = async () => {
    await fetchDadJoke();
    setAnswerRevealed(false);
  };
  const [question, punct, ...answer] = dadJoke.split(/(\?|\.)/);
  return (
    <>
      <Text typography="display4" fontWeight="normal">
        {question}
        {punct}
      </Text>
      <Spacer />
      {answerRevealed || !answer ? (
        <Text typography="display4" fontWeight="normal">
          {answer}
        </Text>
      ) : (
        <PrimaryButton onClick={() => setAnswerRevealed(true)}>
          Show Answer!
        </PrimaryButton>
      )}
      <Spacer />
      <PrimaryButton onClick={handleRequestAnother}>
        That one was terrible, load another
      </PrimaryButton>
    </>
  );
};
