import styled from 'styled-components';

const StyledName = styled.span`
  color: #ce3356;
`;

export const INITIAL_PROMPTS = [
  (p: string) => (
    <>
      <StyledName>{p}</StyledName>, you're up first!
    </>
  ),
  (p: string) => (
    <>
      Get us started, <StyledName>{p}</StyledName>!
    </>
  ),
  (p: string) => (
    <>
      Starting things off, it's <StyledName>{p}</StyledName>!
    </>
  ),
];

export const PROMPTS = [
  (p: string) => (
    <>
      <StyledName>{p}</StyledName>, it's your turn!
    </>
  ),
  (p: string) => (
    <>
      It's <StyledName>{p}'s</StyledName> turn next!
    </>
  ),
  (p: string) => (
    <>
      You're up, <StyledName>{p}</StyledName>.
    </>
  ),
  (p: string) => (
    <>
      What's your update, <StyledName>{p}</StyledName>?
    </>
  ),
  (p: string) => (
    <>
      What's new, <StyledName>{p}</StyledName>?
    </>
  ),
  (p: string) => (
    <>
      <StyledName>{p}</StyledName>, you're up.
    </>
  ),
  (p: string) => (
    <>
      Next up, it's <StyledName>{p}</StyledName>.
    </>
  ),
];

export const FINAL_PROMPTS = [
  (p: string) => (
    <>
      <StyledName>{p}</StyledName>, we saved the best for last!
    </>
  ),
  (p: string) => (
    <>
      Last but not least, it's <StyledName>{p}</StyledName>!
    </>
  ),
  (p: string) => (
    <>
      And finally, <StyledName>{p}</StyledName> can wrap things up.
    </>
  ),
];
