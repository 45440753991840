import { FC} from 'react';

const HANGMAN_URL = 'https://hangman-nerdclub.vercel.app/';

export const Hangman: FC = () => {
  document.location.href = HANGMAN_URL;
  return (
    <>
    </>
  );
};
