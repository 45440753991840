import { PrimaryButton, Text } from '@moonpig/launchpad-components';
import { FC, useState, useCallback, useEffect } from 'react';
import { Spacer } from './Spacer';

export const Riddle: FC = () => {
  const [riddle, setRiddle] = useState<{ question: string; answer: string }>({
    question: '',
    answer: '',
  });

  const fetchRiddle = useCallback(async () => {
    try {
      const { riddle: question, answer } = await fetch(
        'https://riddles-api.vercel.app/random',
        {
          headers: {
            Accept: 'application/json',
          },
        }
      ).then((r) => {
        return r.json();
      });
      setRiddle({
        question,
        answer,
      });
    } catch (err) {
      console.warn('Failed to fetch Dad joke: ', err);
    }
  }, []);

  useEffect(() => {
    fetchRiddle();
  }, [fetchRiddle]);

  const [answerRevealed, setAnswerRevealed] = useState(false);
  const handleRequestAnother = async () => {
    await fetchRiddle();
    setAnswerRevealed(false);
  };
  const { question, answer } = riddle;
  return (
    <>
      <Text typography="display4" fontWeight="normal">
        {question}
      </Text>
      <Spacer />
      {answerRevealed || !answer ? (
        <Text typography="display4" fontWeight="normal">
          {answer}
        </Text>
      ) : (
        <PrimaryButton onClick={() => setAnswerRevealed(true)}>
          Show Answer!
        </PrimaryButton>
      )}
      <Spacer />
      <PrimaryButton onClick={handleRequestAnother}>
        That one was terrible, load another
      </PrimaryButton>
    </>
  );
};
