type TeamState = {
  name: string;
  members: string[];
  selected: string;
  previouslySelected: string[];
  absent: string[];
}

type Action = {
  type: string,
  payload: {
    [key: string]: any
  }
}

export const appStateReducer = (_: any, action: { type: string }) => {
  if (action.type === 'GO_TO_AOBS') {
    return 'AOBS';
  }

  if (action.type === 'GO_TO_FINISHED') {
    return 'FINISHED';
  }

  return 'START';
}

export const teamStateReducer = (teamState: TeamState, { type, payload }: Action) => {
  switch (type) {
    case 'SET_TEAM':
      return {
        ...teamState,
        name: payload.name,
        members: payload.members
      }

    case 'SELECT_PARTICIPANT':
      return {
        ...teamState,
        selected: payload.selected,
      }

    case 'SET_PREVIOUSLY_SELECTED':
      return {
        ...teamState,
        previouslySelected: [...teamState.previouslySelected, payload.selected]
      }

    case 'SET_ABSENT_PARTICIPANT': {
      return {
        ...teamState,
        absent: [...teamState.absent, payload.participant]
      }
    }

    case 'REMOVE_ABSENT_PARTICIPANT': { 
      return { 
        ...teamState, 
        absent: teamState.absent.filter(
          (absentParticipant) => absentParticipant !== payload.participant
        ),
      }
    }

    default:
      return teamState
  }

}