import { forwardRef } from 'react';

const SoundPlayer = forwardRef<HTMLAudioElement, { src: string }>(
  ({ src }, ref) => {
    return (
      <>
        <audio key={src} ref={ref} src={src} />
      </>
    );
  }
);

export default SoundPlayer;
