import { FC, useState } from "react";
import styled from "styled-components";
import { Select, FormControlLabel, Switch } from "@moonpig/launchpad-forms";
import { SOUND_CONFIG } from "../constants";
import { getRandomArrayIndex } from "../utils/getRandomArrayIndex";
import { Flex } from "@moonpig/launchpad-components";

type SoundEffectOptionsProps = {
  selectedOption: string;
  shuffleMode: boolean;
  setSelection: (event: string) => void;
  setShuffleMode: (event: boolean) => void;
};

const StyledSelect = styled(Select)<{ isHidden: boolean }>`
  ${(props) => props.isHidden && `opacity: 0`}
`;

const selectRandomSong = () => {
  const songs = Object.keys(SOUND_CONFIG);
  const index = getRandomArrayIndex(songs);
  return songs[index];
};

const SoundEffectOptions: FC<SoundEffectOptionsProps> = ({
  selectedOption,
  shuffleMode,
  setSelection,
  setShuffleMode,
}) => {
  const [hidden, setHidden] = useState(true);

  return (
    <Flex alignItems="center" justifyContent="flex-end">
      <StyledSelect
        onChange={(e) => {
          if (e.target.value === "random") {
            const randomSelection = selectRandomSong();
            setHidden(true);
            setSelection(randomSelection);
            return;
          }
          setSelection(e.target.value);
        }}
        value={selectedOption}
        label="Sound Effect"
        name="Sound Effect"
        isHidden={hidden}
        options={[
          { value: "off", label: "Off" },
          { value: "random", label: "Random!" },
          ...Object.entries(SOUND_CONFIG).map(([key, { name }]) => ({
            value: key,
            label: name,
          })),
        ]}
      />
      <Flex flexDirection="column">
        <FormControlLabel name="hide-dropdown" label="Hide">
          <Switch
            value="hidden"
            checked={hidden}
            onChange={() => setHidden(!hidden)}
          />
        </FormControlLabel>
        <FormControlLabel name="shuffle-mode" label="Shuffle">
          <Switch
            value="shuffle"
            checked={shuffleMode}
            onChange={() => {
              setSelection(selectRandomSong());
              setShuffleMode(!shuffleMode);
            }}
          />
        </FormControlLabel>
      </Flex>
    </Flex>
  );
};

export default SoundEffectOptions;
