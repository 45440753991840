import { FC, ReactNode } from 'react';
import { getRandomArrayIndex } from '../utils/getRandomArrayIndex';

import { INITIAL_PROMPTS, PROMPTS, FINAL_PROMPTS } from '../prompts';

const generateInitialPrompt = (participant: string): ReactNode =>
  INITIAL_PROMPTS[getRandomArrayIndex(INITIAL_PROMPTS)](participant);

const generatePrompt = (participant: string): ReactNode =>
  PROMPTS[getRandomArrayIndex(PROMPTS)](participant);

const generateFinalPrompt = (participant: string): ReactNode =>
  FINAL_PROMPTS[getRandomArrayIndex(FINAL_PROMPTS)](participant);

export const Prompt: FC<{
  participant: string;
  initial?: boolean;
  final?: boolean;
}> = ({ participant, initial, final }) => {
  return (
    <>
      {initial && generateInitialPrompt(participant)}
      {final && generateFinalPrompt(participant)}
      {!initial && !final && generatePrompt(participant)}
    </>
  );
};
